import { IHousfyError } from "@/models/HousfyError";
import ErrorService from "@/services/ErrorService";
import * as Sentry from "@sentry/browser";

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("ErrorService", () => {
  describe("handleGenericError", () => {
    it("Should show a console message with the error", () => {
      // Given
      spyOn(console, "error");
      const error: IHousfyError = {
        message: "Error",
      };
      // When
      ErrorService.handleGenericError(error);
      // Then
      expect(console.error).toHaveBeenCalledWith("Error", error.message, {
        error,
      });
    });

    it("Should trigger a sentry", () => {
      // Given
      spyOn(Sentry, "captureException");
      const error: IHousfyError = {
        message: "Error",
      };
      // When
      ErrorService.handleGenericError(error);
      // Then
      expect(Sentry.captureException).toHaveBeenCalledWith(
        "Error: " + error.message,
        {
          extra: { error },
        }
      );
    });
  });
});
